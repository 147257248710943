<template>
  <div>
    <CRow>
      <CCol lg="12">
        <marquee
              class="mb-1 py-2 mt-3 mb-3 bg-dark text-white" style="position: fixed; top:100px;z-index: 100;"
            >
              <span class="badge badge-success ml-2">{{btc}}</span> BTC 
              <span class="badge badge-danger ml-2">{{eth}}</span> ETH 
              <span class="badge badge-success ml-2">{{doge}}</span> DOGE 
              <span class="badge badge-danger ml-2">{{mana}}</span> MANA 
              <span class="badge badge-danger ml-2">{{shiba}}</span> SHIBA 
              <span class="badge badge-success ml-2">{{trx}}</span> TRX 
        </marquee>
      </CCol>
     <!--  <CCol sm="6" lg="3">
        <CWidgetIcon
          :header="btc"
          text="Bitcoin"
          color="gradient-warning"
          :icon-padding="false"
        >
          <CIcon name="cil-chart-line" width="24"/>
        </CWidgetIcon>
      </CCol>
      <CCol sm="6" lg="3">
        <CWidgetIcon
          :header="eth"
          text="Ethereum"
          color="gradient-dark"
          :icon-padding="false"
        >
          <CIcon name="cil-chart-line" width="24"/>
        </CWidgetIcon>
      </CCol>
      <CCol sm="6" lg="3">
        <CWidgetIcon
          :header="doge"
          text="Doge"
          color="gradient-info"
          :icon-padding="false"
        >
          <CIcon name="cil-chart-line" width="24"/>
        </CWidgetIcon>
      </CCol>
      <CCol sm="6" lg="3">
        <CWidgetIcon
          :header="mana"
          text="Mana"
          color="gradient-success"
          :icon-padding="false"
        >
          <CIcon name="cil-chart-line" width="24"/>
        </CWidgetIcon>
      </CCol> -->
      <CCol lg="12" class="mt-4">
        <CCard class="bg-transparent border-0">
        <CCardHeader>
          <slot name="header">
            <!-- <CIcon name="cil-grid"/> --><h4 class="float-left">Tools</h4> 
            <p class="float-right ">Total Deposits : {{depo}} USDT</p>
            <!-- <button type="button"  @click="modalDepo = true" class="float-right btn btn-success form-control-sm">Add Deposit</button> -->
          </slot>
        </CCardHeader>
        <CCardBody class="p-0 bg-transparent ">
        <div >
        <CRow>
          <CCol md="4" sm="6">
          <div class="card">
            <div class="card-header content-center">
              <h3>SHIBA</h3>
            </div>
            <div class="card-header content-center text-white p-0 bg-light">
              <img
                  :src="require('../../assets/images/shiba.png')"
                  style="width: 110px; margin: 10 auto"
               />
              <!-- <cchartlinesimple background-color="rgba(255,255,255,.1)" data-points="1,13,9,17,34,41,38" label="Followers" labels="months" class="c-chart-brand"></cchartlinesimple> -->
              
            </div>
            <div class="card-body row text-center">
              <div class="col">
                <div class="text-value-lg"> 3 Months </div><div class="text-uppercase text-muted small"> Lock-Up Periods </div>
              </div>
              <div class="c-vr"></div>
              <div class="col">
                <div class="text-value-lg"> 5% - 50% / Month </div>
                <div class="text-uppercase text-muted small"> Earning </div>
              </div>
            </div>
            <div class="card-footer text-center"><button type="button"  @click="showStake(5)" class="btn btn-success btn-block btn-pill">Stake</button></div>
            </div>
          </CCol>
          <CCol md="4" sm="6">
          <div class="card">
            <div class="card-header content-center">
              <h3>MANA</h3>
            </div>
            <div class="card-header content-center text-white p-0 bg-light">
              <img
                  :src="require('../../assets/images/mana.png')"
                  style="width: 110px; margin: 10 auto"
               />
              <cchartlinesimple background-color="rgba(255,255,255,.1)" data-points="1,13,9,17,34,41,38" label="Followers" labels="months" class="c-chart-brand"></cchartlinesimple>
              
            </div>
            <div class="card-body row text-center">
              <div class="col">
                <div class="text-value-lg"> 3 Months </div><div class="text-uppercase text-muted small"> Lock-Up Periods </div>
              </div>
              <div class="c-vr"></div>
              <div class="col">
                <div class="text-value-lg"> 5% - 50% / Month </div>
                <div class="text-uppercase text-muted small"> Earning </div>
              </div>
            </div>
            <div class="card-footer text-center"><button type="button"  @click="showStake(4)" class="btn btn-success btn-block btn-pill">Stake</button></div>
            </div>
          </CCol>
          <CCol md="4" sm="6">
          <div class="card">
            <div class="card-header content-center">
              <h3>TRON</h3>
            </div>
            <div class="card-header content-center text-white p-0 bg-light">
              <img
                  :src="require('../../assets/images/tron.png')"
                  style="width: 110px; margin: 10 auto"
               />
              <cchartlinesimple background-color="rgba(255,255,255,.1)" data-points="1,13,9,17,34,41,38" label="Followers" labels="months" class="c-chart-brand"></cchartlinesimple>
              
            </div>
            <div class="card-body row text-center">
              <div class="col">
                <div class="text-value-lg"> 3 Months </div><div class="text-uppercase text-muted small"> Lock-Up Periods </div>
              </div>
              <div class="c-vr"></div>
              <div class="col">
                <div class="text-value-lg"> 5% - 50% / Month </div>
                <div class="text-uppercase text-muted small"> Earning </div>
              </div>
            </div>
            <div class="card-footer text-center"><button type="button"  @click="showStake(6)" class="btn btn-success btn-block btn-pill">Stake</button></div>
            </div>
          </CCol>
          <!-- <CCol md="4" sm="6">
          <div class="card">
            <div class="card-header content-center text-white p-0 bg-light">
              <img
                  :src="require('../../assets/images/nft.png')"
                  style="width: 110px; margin: 10 auto"
               />
              <cchartlinesimple background-color="rgba(255,255,255,.1)" data-points="1,13,9,17,34,41,38" label="Followers" labels="months" class="c-chart-brand"></cchartlinesimple>
              
            </div>
            <div class="card-body row text-center">
              <div class="col">
                <div class="text-value-lg"> 3 Months </div><div class="text-uppercase text-muted small"> Lock-Up Periods </div>
              </div>
              <div class="c-vr"></div>
              <div class="col">
                <div class="text-value-lg"> 5% - 50% / Month </div>
                <div class="text-uppercase text-muted small"> Earning </div>
              </div>
            </div>
            <div class="card-footer text-center"><button type="button"  @click="showStake(7)" class="btn btn-success btn-block btn-pill">Stake</button></div>
            </div>
          </CCol> -->
          <CCol md="4" sm="6">
          <div class="card">
            <div class="card-header content-center">
              <h3>BITCOIN</h3>
            </div>
            <div class="card-header content-center text-white p-0 bg-info">
              <img
                  :src="require('../../assets/images/bitcoin.png')"
                  style="width: 110px; margin: 10 auto"
               />
              <cchartlinesimple background-color="rgba(255,255,255,.1)" data-points="1,13,9,17,34,41,38" label="Followers" labels="months" class="c-chart-brand"></cchartlinesimple>
              
            </div>
            <div class="card-body row text-center">
              <div class="col">
                <div class="text-value-lg"> 3 Months </div><div class="text-uppercase text-muted small"> Lock-Up Periods </div>
              </div>
              <div class="c-vr"></div>
              <div class="col">
                <div class="text-value-lg">5% - 50% / Month </div>
                <div class="text-uppercase text-muted small"> Earning </div>
              </div>
            </div>
            <div class="card-footer text-center"><button type="button"  @click="showStake(1)" class="btn btn-success btn-block btn-pill">Farm</button></div>
            </div>
          </CCol>
          <CCol md="4" sm="6">
          <div class="card">
            <div class="card-header content-center">
              <h3>ETHEREUM</h3>
            </div>
            <div class="card-header content-center text-white p-0 bg-warning">
              <img
                  :src="require('../../assets/images/ethereum.png')"
                  style="width: 110px; margin: 10 auto"
               />
              <cchartlinesimple background-color="rgba(255,255,255,.1)" data-points="1,13,9,17,34,41,38" label="Followers" labels="months" class="c-chart-brand"></cchartlinesimple>
              
            </div>
            <div class="card-body row text-center">
              <div class="col">
                <div class="text-value-lg"> 3 Months </div><div class="text-uppercase text-muted small"> Lock-Up Periods </div>
              </div>
              <div class="c-vr"></div>
              <div class="col">
                <div class="text-value-lg"> 5% - 50% / Month </div>
                <div class="text-uppercase text-muted small"> Earning </div>
              </div>
            </div>
            <div class="card-footer text-center"><button type="button" @click="showStake(2)" class="btn btn-success btn-block btn-pill">Farm</button></div>
            </div>
          </CCol>
          <CCol md="4" sm="6">
          <div class="card">
            <div class="card-header content-center">
              <h3>DOGE</h3>
            </div>
            <div class="card-header content-center text-white p-0 bg-light">
              <img
                  :src="require('../../assets/images/doge.png')"
                  style="width: 110px; margin: 10 auto"
               />
              <cchartlinesimple background-color="rgba(255,255,255,.1)" data-points="1,13,9,17,34,41,38" label="Followers" labels="months" class="c-chart-brand"></cchartlinesimple>
              
            </div>
            <div class="card-body row text-center">
              <div class="col">
                <div class="text-value-lg"> 3 Months </div><div class="text-uppercase text-muted small"> Lock-Up Periods </div>
              </div>
              <div class="c-vr"></div>
              <div class="col">
                <div class="text-value-lg"> 5% - 50% / Month </div>
                <div class="text-uppercase text-muted small"> Earning </div>
              </div>
            </div>
            <div class="card-footer text-center"><button type="button"  @click="showStake(3)" class="btn btn-success btn-block btn-pill">Farm</button></div>
            </div>
          </CCol>
          
        </CRow>
        </div>

        <!-- <CCard accent-color="danger" >
          <CCardBody style="font-size: 11px;">
            <CRow>
              <CCol>
                <div class="text-center"><h4><strong>Please add your deposits first, minimum 50 USDT to do staking!</strong></h4><br>
                  <button type="button" to="/deposits/list"  class="float-center btn btn-success form-control-sm">Add Deposit</button>
                  <CButton color="success" to="/deposits/list" class=""
                        >Add Deposit</CButton>
                </div>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard> -->


    <CSpinner v-show="mySpinner" color="primary" style="position:fixed;left: 50%;top:50%"/>

    </CCardBody>
  </CCard>  
  
  </CCol>
    </CRow>
    <CModal
      title="Stake Options"
      color="dark"
      id="modalStake"      
      :show.sync="modalStake"
    >
    <h5 class="text-center">Your Wallet : {{wallet}} USDT</h5>
    <hr>
    <CAlert show color="danger" v-show="zerodepo">
              Warning <a href="#" class="alert-link">You have insufficient funds. Please deposit your wallet first!</a>.
    </CAlert>
    <div class='row'>
      <div class="col">
        <button type="button" @click="addAmountStake('50',1, 'Earning 0.1 up to 2 (USDT) per Day')" class="btn btn-success btn-block btn-pill">50</button>
      </div>
      <div class="col">
        <button type="button" @click="addAmountStake('100',2, 'Earning 0.5 up to 5 (USDT) per Day')" class="btn btn-success btn-block btn-pill">100</button>
      </div>
      <div class="col">
        <button type="button" @click="addAmountStake('500',3, 'Earning 3 up to 7 (USDT) per Day')" class="btn btn-success btn-block btn-pill">500</button>
      </div>
      <div class="col">
        <button type="button" @click="addAmountStake('1000',4, 'Earning 5 up to 10 (USDT) per Day')" class="btn btn-success btn-block btn-pill">1000</button>
      </div>
      <div class="col">
        <button type="button" @click="addAmountStake('3000',5, 'Earning 20 up to 50 (USDT) per Day')" class="btn btn-success btn-block btn-pill">3000</button>
      </div>
      <div class='col-12'>
        <br>
        <p class="text-center">{{earn}}</p>
      </div>
    </div>    
    <input type="hidden" v-model="amountd">
    <template #footer>
        <h5 class="float-left">Your Stake Amount : {{amountd}} USDT</h5>
        <CButton @click="modalStake = false" color="light">Cancel</CButton>
        <CButton @click="stakeNow" color="dark">Process</CButton>
    </template>        
    </CModal>
    <CModal title="Error" :show.sync="myEmpty" size="sm">
      Failed! Please choose amount deposit...
    </CModal>
    <CModal title="Error" :show.sync="myError3" size="sm">
      Failed! Please check your balance again...
    </CModal>
    <CModal title="Error" :show.sync="myError" size="sm">
      Failed! Please check your balance again...
    </CModal>
    <CModal title="Error" :show.sync="myError2" size="sm">
      Failed! You have insufficient fund. Please deposit first to do staking.
    </CModal>
    <CModal title="Success" :show.sync="mySuccess" size="sm">
      Success! Your staking request is succeed. Please check your email for the notification.
      <template #footer>
        <CButton @click="goMystake()" color="dark">OK</CButton>
    </template>
    </CModal>
    <!-- <CModal
      title="Add Deposit"
      color="dark"
      id="modalDepo"      
      :show.sync="modalDepo"
    >
    <h5 class="text-center">Your Current Wallet : {{wallet}} USDT</h5>
    <hr>
    <div class='row'>
      <div class="col">
        <button type="button" @click="addAmount('50')" class="btn btn-success btn-block btn-pill">50</button>
      </div>
      <div class="col">
        <button type="button"  @click="addAmount('100')" class="btn btn-success btn-block btn-pill">100</button>
      </div>
      <div class="col">
        <button type="button"  @click="addAmount('500')" class="btn btn-success btn-block btn-pill">500</button>
      </div>
      <div class="col">
        <button type="button"  @click="addAmount('1000')" class="btn btn-success btn-block btn-pill">1000</button>
      </div>
      <div class="col">
        <button type="button"  @click="addAmount('3000')" class="btn btn-success btn-block btn-pill">3000</button>
      </div>
    </div>
    <input type="hidden" v-model="amountd">
    <template #footer>
        <h5 class="float-left">You Deposit Amount : {{amountd}} USDT</h5>
        <CButton @click="modalDepo = false" color="light">Cancel</CButton>
        <CButton @click="depoNow" color="dark">Process</CButton>
    </template>
      
      
    </CModal> -->
  </div>
</template>
 
<script>
import MarqueeText from 'vue-marquee-text-component';
import 'jquery/dist/jquery.min.js';
// import 'bootstrap/dist/css/bootstrap.min.css';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import axios from 'axios';
import $ from 'jquery'; 
let user = JSON.parse(localStorage.getItem('user'));
// let level = user.level;
export default {
  filters: {
    rupiah: function(value) {
      var formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',

      });

      return formatter.format(value); /* $2,500.00 */
    }
  },
  components: {
    MarqueeText
  },
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  mounted(){
    this.mid = user.id_member;
    this.loadData();
    if(this.depo>0){
      this.zerodepo =false;
      // alert("kosong");
    }
    else{
      this.zerodepo =true;
    }
  },
  data: function() {
        return {
            products:[],
            amountd :"0",
            zerodepo :false,
            trx:"0",
            shiba:"0",
            doge:"0",
            mana:"0",
            eth:"0",
            btc:"0",
            cid:"",
            mid:"",
            pid:"",
            depo: "0",
            conversi : 0,
            conv : "0",
            earn : "Earning 0.1 up to 2 (USDT) per Day",
            wallet:"0",
            modalStake:false,
            modalDepo:false,
            modalWarn:false,
            mySpinner :false,            
            myEmpty :false,            
            myError :false,            
            myError2 :false,            
            myError3 :false,            
            mySuccess :false,            
            tahun: new Date().getFullYear(),
        }
  },
  methods: {
    goMystake: function () {
      this.$router.push('/mystake/');
    },
    addAmount: function(ad) {
      this.amountd = ad;
      // alert(ad);
    },
    addAmountStake: function(ad, pid, earn) {
      this.amountd = ad;
      // alert(ad+" "+this.wallet);
      if(+this.depo>= +ad){
        this.pid = pid;
        this.earn = earn;  
      }
      else{
        this.myError3 =true;
      }
      

      // alert(ad);
    },
    showStake: function(cid) {
        if(cid=='1'){
          this.conv = this.btc;
        }
        else if(cid=='2'){
          this.conv = this.eth;
        }
        else if(cid=='3'){
          this.conv = this.doge;
        }
        else if(cid=='4'){
          this.conv = this.mana;
        }
        else if(cid=='5'){
          this.conv = this.shiba;
        }
        else if(cid=='6'){
          this.conv = this.trx;
        }
        else{
          this.conv = "";
        }
        // alert(this.conv);
      if(this.depo>0){
        this.modalStake = true;
        this.cid = cid;  
      }
      else{
        this.myError2 = true;
        this.cid = cid;  
      }
      
      // alert(ad);
    },
    loadData: function() {
          let id_member = user.id_member;
          let idm;
          if(id_member=="0") {
            idm = "";
          }
          else{
            idm = id_member;
          }
            axios
            .get(
              process.env.VUE_APP_BASE_URL+"api/public/index.php/dashboardstat/"+idm+"?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu",
              user
            )
            .then((response) => {
              // if (response.data == "sukses") {
                
                this.wallet = parseFloat(response.data.wallet);
                this.depo = parseFloat(response.data.depo);
                
                user.wallet=response.data.wallet;
            });

            axios
            .get(
              "https://indodax.com/api/summaries",
              false
            )
            .then((response) => {
              // if (response.data == "sukses") {
                
                this.eth = parseFloat(response.data.tickers.eth_usdt.buy)+ " USDT";
                this.btc = parseFloat(response.data.tickers.btc_usdt.buy)+" USDT";
                // this.doge = 0+" USDT";
                // this.mana = 0+" USDT";
               
            });

            axios
            .get(
              "https://api1.binance.com/api/v3/ticker/price?symbol=DOGEUSDT",
              false
            )
            .then((response) => {
              this.doge = parseFloat(response.data.price);              
            });

            axios
            .get(
              "https://api1.binance.com/api/v3/ticker/price?symbol=MANAUSDT",
              false
            )
            .then((response) => {
              this.mana = parseFloat(response.data.price);              
            });

            axios
            .get(
              "https://api1.binance.com/api/v3/ticker/price?symbol=SHIBUSDT",
              false
            )
            .then((response) => {
              this.shiba = parseFloat(response.data.price);              
            });

            axios
            .get(
              "https://api1.binance.com/api/v3/ticker/price?symbol=TRXUSDT",
              false
            )
            .then((response) => {
              this.trx = parseFloat(response.data.price);              
            });
      if (this.tahun == null){
          $('#datatable').DataTable({
            "processing": true,
              "serverSide": true,
               "pageLength": 50,
              "ajax":{
                       "url": process.env.VUE_APP_BASE_URL+"api/public/index.php/stake/?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu",
                       "dataType": "json",
                       "type": "POST"
                     },
              "columns": [
                  { "data": "no" },
                  { "data": "coin_name" },
                  { "data": "coin_code", className: "text-center" },
                  { "data": "coin_desc", className: "text-center" },
                  { "data": "edit" },
              ]  

          });
          
          $.fn.dataTableExt.sErrMode = 'throw';
          
        // })
      }
      else{
          $('#datatable').DataTable({
                      "processing": true,
              "serverSide": true,
               "pageLength": 50,
              "ajax":{
                       "url": process.env.VUE_APP_BASE_URL+"api/public/index.php/stake/?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu",
                       "dataType": "json",
                       "type": "POST"
                     },
              "columns": [
                  { "data": "no" },
                  { "data": "coin_name" },
                  { "data": "coin_code", className: "text-center" },
                  { "data": "coin_desc", className: "text-center" },
                  { "data": "edit" },
              ] 
 
          });
          
          $.fn.dataTableExt.sErrMode = 'throw';
          
        // })
      }
      
    },
    stakeNow: function (event) {
      // Simple POST request with a JSON body using axios
      this.mySpinner = true;
      this.modalStake = false;
      let konver = parseFloat(this.amountd) / parseFloat(this.conv);
      konver = (Math.round(parseFloat(konver)*100000000)/ 100000000)
      // alert(this.amountd +" "+this.conv + " : "+konver);
      const stk = {
        email: user.id,
        konversi :  konver,
        mid: this.mid,
        cid: this.cid,
        pid: this.pid,
        amount_d: this.amountd,
      };
      // alert(JSON.stringify(stk));
      if((this.amountd == null || this.amountd == "0")) {
        this.myEmpty = true;
      }
      else{
        axios
          .post(
            process.env.VUE_APP_BASE_URL+"api/public/index.php/addstake/?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu",
            stk
          )
          .then((response) => {
            // console.log(response);
            // alert(JSON.stringify(response.data));
            // this.tabs = response.data;/
            this.mySpinner = false;
            if (response.data == "ok") {
              // let idx = response.data.result.txn_id;
              // alert(idx);
              this.mySuccess = true;
              this.modalStake = false;
              if(this.mySuccess==false){
                user.wallet= +user.wallet - +this.amountd;
                this.$router.push("/mystake/");  
              }
              

              
            } else {
              this.myError = true;
              this.modalStake = false;
            }
          });
      }
    },
  }
}
</script>